@import "~font-awesome/css/font-awesome.min.css";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './assets/css/custom/custom-bootstrap.scss';
@import './assets/bootstrap-icons/font/bootstrap-icons.scss';
@font-face {
    font-family: "Inter";
    src: url("/./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
}

@font-face {
    font-family: "Mukta-Mahee-700";
    src: url("/./assets/fonts/Mukta_Mahee/MuktaMahee-Bold.ttf");
}

@font-face {
    font-family: "Poppins";
    src: url("/./assets/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-400";
    src: url("/./assets/fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: 'MyFont-English';
    src: url('./assets/fonts/oswald/Oswald-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Oswald';
    src: url('./assets/fonts/oswald/Oswald-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Martel Sans';
    src: url('./assets/fonts/martel/MartelSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    src: url('./assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'MyFont-Tamil';
    src: url('./assets/fonts/kavivanar/Kavivanar-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Kavivanar";
    src: url('./assets/fonts/kavivanar/Kavivanar-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Noto Sans";
    src: url('./assets/fonts/notoTamil/NotoSansTamil-VariableFont_wdth,wght.ttf') format('truetype');
}

// * {
//     transition: all 0.3s ease;
// }
html,
body,
ul,
li {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
}

html,
body {
    height: 100% !important;
    overflow-x: hidden;
    z-index: 0;
}

body {
    font-family: $font-martel;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
    font-family: $font-oswald;
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

section {
    min-height: 600px;
    max-height: fit-content;
    background: #F2F3F5;
    box-shadow: inset 2px 2px 3px 0px #e5e5e5;
    overflow: hidden;
}

header,
footer {
    box-sizing: border-box;
}

// Scorll Bar Starts

/* width */

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// Scroll Bar Ends
// Tool Bar Starts
.p-toolbar {
    background: none !important;
    border: 0 !important;
    padding: 10px !important;
    border-radius: 0 !important;
    h5 {
        margin-left: 15px !important;
    }
}

.p-button {
    padding: 10px 15px !important;
    border-radius: 3px !important;
}

.toolbar {
    button,
    .p-fileupload {
        margin-right: 15px !important;
        font-size: 14px !important;
    }
    .p-button-label {
        font-weight: 300 !important;
    }
}

// Tool Bar Ends
// Dialog Starts
.ib-dialog.p-dialog {
    margin: 15px;
    .p-dialog-header {
        padding-bottom: 15px !important;
        z-index: 1001 !important;
    }
    .p-dialog-header .p-dialog-title {
        font-size: unset;
        font-weight: unset;
    }
    .p-button-label {
        font-weight: 500 !important;
    }
    h6 {
        font-size: 17px;
    }
}

// Dialog Ends
// Form Starts
.form-control,
.p-dropdown,
.p-inputtext,
textarea,
small {
    font-family: $font-martel !important;
}

small {
    font-size: 11px !important;
}

textarea {
    font-size: 12px;
    width: 92%;
    border: 1px solid #2ec7c0ed !important;
    min-height: 125px;
    border-radius: 5px;
}

.form-control,
.p-dropdown,
.p-inputtext {
    width: 205px;
    border: 1px solid #2ec7c0ed !important;
    border-radius: 3px !important;
    font-size: 13px !important;
}


/* .form-control,
.p-dropdown {
    padding-top: 9px;
} */

.form-control {
    padding-top: 9px;
}

.p-calendar {
    width: 205px;
    height: 39px !important;
}

.same_line_fix {
    display: block;
    width: 205px;
}

.p-datepicker {
    table {
        font-size: 14px !important;
        margin: 11px 0 !important;
    }
    table td {
        padding: 0px !important;
    }
    .p-datepicker-header {
        padding: 0 !important;
    }
}

.p-inputgroup {
    height: 36.1px !important;
}

.p-dropdown {
    .p-inputtext {
        border: none !important;
    }
}

.p-paginator {
    .p-dropdown {
        width: inherit !important;
        height: unset !important;
        padding: 0 !important;
        .p-inputtext {
            width: unset !important;
            height: 35px !important;
            padding-right: 15px !important;
            margin: 2px !important;
        }
        .p-dropdown-trigger {
            width: fit-content !important;
            padding: 0 10px !important;
        }
    }
    .pi {
        font-size: 14px !important;
    }
}

.form-control-label {
    font-size: 13px !important;
    padding-left: 3px !important;
    padding-bottom: 5px;
    font-weight: 500 !important;
}

p-radiobutton {
    margin-right: 15px;
    label {
        font-size: 14px;
    }
}

.p-dropdown {
    height: 39px;
}

.p-inputtext {
    font-size: 13px !important;
    height: 39px !important;
}

.p-dropdown .p-dropdown-label {
    line-height: 1 !important;
}


/*ng select drop down */

.ng-select .ng-select-container {
    height: 19px !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    display: none;
}

.ng-select span.ng-arrow-wrapper:before {
    content: "\f107";
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    text-rendering: auto;
    color: #2ec7c0ed;
}

.ng-dropdown-panel {
    color: #333;
    left: 0px;
    top: 37px;
    background-color: white;
    border: 1px solid #2ec7c0ed;
    font-size: 14px !important;
    .ng-option.ng-option-marked {
        background-color: #f2f2f2;
    }
    .ng-option.ng-selected {
        background-color: #007bff;
        color: #fff;
    }
    .ng-dropdown-panel-items .ng-option {
        padding: 5px 10px;
        font-size: 13px !important;
    }
}

.p-datatable {
    .form-control {
        width: 165px !important;
    }
}

// Form Ends
// Translation Fonts
.english-font,
.english-regular-bold {
    font-family: 'Oswald', sans-serif !important;
}

.english-regular {
    font-family: $font-martel;
    font-size: 13px !important;
}

.tamil-kavi {
    font-family: 'Kavivanar', sans-serif !important;
}

.tamil-font {
    font-family: 'Kavivanar', sans-serif !important;
    font-size: 13px !important;
}

.tamil-regular {
    font-family: "Noto Sans" !important;
    font-size: 13px !important;
}

.tamil-regular-bold {
    font-family: "Noto Sans" !important;
    font-size: 13px !important;
    font-weight: 600;
}

.tamil-header-font {
    font-family: 'Kavivanar', sans-serif !important;
    font-size: 20px !important;
    font-weight: 500;
}

.english-label {
    font-family: "Oswald", sans-serif !important;
    font-size: 14px;
    font-weight: normal !important;
}

.tamil-label {
    font-family: "Noto Sans" !important;
    font-size: 12px !important;
    // font-size: calc(10px + 0.5vw) !important;
    font-weight: 600 !important;
    white-space: nowrap;
}

.english-heading {
    font-family: 'Oswald', sans-serif !important;
    font-size: 17px !important;
}

.tamil-heading {
    font-family: 'Kavivanar', sans-serif !important;
    font-size: 15px !important;
    font-weight: 800 !important;
}

h6.tamil-heading {
    font-family: 'Kavivanar', sans-serif !important;
    font-size: 13px !important;
    font-weight: 700 !important;
}

.english-small {
    font-family: $font-martel !important;
    font-size: 12px !important;
}

.tamil-small {
    font-size: 12px !important;
    font-family: 'Kavivanar', sans-serif !important;
}

.english-button {
    font-size: 13px !important;
    font-family: $font-martel !important;
    font-weight: 600 !important;
    padding-top: 3px;
}

.tamil-button {
    font-family: "Noto Sans";
    font-size: 12px !important;
    font-weight: 600 !important;
}

.is-invalid {
    border: 1px solid red !important;
}

.cursor-pointer {
    cursor: pointer;
}

p-calendar.is-invalid,
p-inputNumber.is-invalid {
    border: none !important;
}

p-calendar.is-invalid .p-inputtext,
p-inputNumber.is-invalid .p-inputtext {
    border: 1px solid red !important;
}

.custom-button {
    width: fit-content !important;
    .button-label {
        display: block;
        // margin-right: 10px;
        // text-shadow: 1px 1px 3px #383838;
        text-shadow: 1px 0px 0px #383838c4;
    }
    .pi {
        font-size: 13px !important;
        font-weight: 600 !important;
        margin-left: 10px;
    }
}

@media screen and (max-width: 575px) {
    .ib-dialog.p-dialog {
        .p-inputtext,
        .p-inputnumber,
        .form-control,
        .p-dropdown,
        .same_line_fix,
        .p-calendar {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 991.86px) {
    header {
        height: 139px;
        z-index: 999;
        position: relative;
        ib-navbar> {
            .container-fluid {
                height: 139px;
            }
        }
    }
}